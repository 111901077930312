// extracted by mini-css-extract-plugin
export var button = "extras-module--button--a8541 text-sm md_text-base py-ms-2 px-ms1";
export var card = "extras-module--card--5028a text-center min-w-[300px]";
export var cardImage = "extras-module--cardImage--f632f group-hover_scale-105 transition aspect-4/3";
export var cardWrapper = "extras-module--cardWrapper--56e45 mx-auto flex gap-ms2 overflow-x-auto pb-ms1";
export var closeButton = "extras-module--closeButton--f61af absolute w-ms2 h-ms2 inline-block align-middle fill-white pointer-events-none left-0 md_-left-ms5 -top-ms4 md_top-0";
export var container = "extras-module--container--4e718 container mx-auto";
export var imageWrapper = "extras-module--imageWrapper--61bd2 relative overflow-hidden group w-full";
export var popUpContainer = "extras-module--popUpContainer--667ca max-h-[80vh] bg-white flex flex-col lg_flex-row [&>div]_w-full [&>div]_lg_w-[unset]";
export var popUpContent = "extras-module--popUpContent--0780f p-ms4";
export var popUpImage = "extras-module--popUpImage--65782 aspect-4/3";
export var price = "extras-module--price--10926 block mb-base text-stone-700";
export var title = "extras-module--title--cc9aa hFour mb-base text-stone-700";