import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import DialogModule from '../dialogModel/DialogModel'
import CloseIcon from '../../images/icons/Close'
import useTailwindMerge from '../../hooks/useTailwindMerge'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import Slider from '../slider/slider'
import * as defaultClasses from './extras.module.css'

const Extras = ({ extras, parentClasses }) => {
    const classes = useTailwindMerge(defaultClasses, parentClasses)

    const [isOpen, setIsOpen] = useState(false)
    const [currentExtra, setCurrentExtra] = useState([])

    return (
        <>
            <div className={`extras ${classes.container}`}>
                <div className={classes.cardWrapper}>
                    {extras.map((extra, index) => (
                        <div
                            key={index}
                            className={classes.card}
                            onClick={() => {
                                setIsOpen(true)
                                setCurrentExtra(extra)
                            }}
                        >
                            <div className={classes.imageWrapper}>
                                <GatsbyImage
                                    image={getImage(
                                        extra?.image?.childImageSharp
                                            ?.gatsbyImageData
                                    )}
                                    alt={extra?.title}
                                    className={classes.cardImage}
                                />
                            </div>
                            <h4 className={`mt-base ${classes.title}`}>
                                {extra?.title}
                            </h4>
                            <strong className={classes.price}>
                                {extra?.price}
                            </strong>
                            <button
                                className={'btnPrimary ' + classes.button}
                                onClick={() => setIsOpen(true)}
                                title="Read more"
                            >
                                Read more
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <DialogModule isOpen={isOpen} setIsOpen={setIsOpen}>
                <div className="flex min-h-full items-center justify-center p-ms2 md_p-ms7 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-screen-xl transform text-left align-middle shadow-xl transition-all">
                            <CloseIcon className={classes.closeButton} />
                            <div className={classes.popUpContainer}>
                                <Slider effect="fade">
                                    {currentExtra?.gallery?.map(
                                        (extra, index) => (
                                            <div
                                                key={index}
                                                className={classes.imageWrapper}
                                            >
                                                <GatsbyImage
                                                    image={getImage(
                                                        extra?.image
                                                            ?.childImageSharp
                                                            ?.gatsbyImageData
                                                    )}
                                                    alt={extra?.title}
                                                    className={
                                                        classes.popUpImage
                                                    }
                                                />
                                            </div>
                                        )
                                    )}
                                </Slider>
                                <div className={classes.popUpContent}>
                                    <h4 className={classes.title}>
                                        {currentExtra?.title}
                                    </h4>
                                    <strong className={classes.price}>
                                        {currentExtra?.price}
                                    </strong>
                                    <p>{currentExtra?.text}</p>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </DialogModule>
        </>
    )
}

export default Extras
