import React from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './listTwo.module.css'

const ListTwo = ({
    title = 'Hello',
    text = 'How are you?',
    showTitle = true,
    listTwoData,
    parentClasses,
}) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const list = listTwoData.map((ld, index) => (
        <div role="listitem" key={index} className={classes.listItem}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 64 64"
                className={classes.svg}
            >
                <circle cx="32" cy="32" r="12" />
                <path d="M39.682 35.636A8.483 8.483 0 0 1 34.2 40.21M32 16V8M40.907 16.572l2.186-3.785M48.214 23.546l3.785-2.185M48 32h8M49 41.815 52.785 44M42.269 48.214l2.185 3.785M32 48v8M23.093 47.428l-2.186 3.785M17.358 41.361l-3.785 2.185M16 32H8M18.144 24l-3.785-2.185M24.454 17.358l-2.185-3.785" />
            </svg>
            <h3 className={`hFour ` + classes.listItemTitle}>
                {ld.title}
                <span className={classes.stop}>.</span>
            </h3>
            <p className={classes.listItemText}>{ld.text}</p>
        </div>
    ))

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `hOne ${titleClass}`
    return (
        <div className={classes.container}>
            {showTitle === true && (
                <div className={classes.contentWrapper}>
                    <h3 className={titleClassCombined}>{title}</h3>
                    <p className={classes.text}>{text}</p>
                </div>
            )}
            <div className={classes.listContainer}>
                <div role="list" className={classes.listWrapper}>
                    {list}
                </div>
            </div>
        </div>
    )
}

export default ListTwo
