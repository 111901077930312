import React from 'react'
import { useStyle } from '../../hooks/classify'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as defaultClasses from './sideBySide.module.css'

const SideBySide = ({
    image,
    title = 'Welcome to my website',
    text = 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    imagePosition,
    children,
    parentClasses,
}) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `hTwo ${titleClass}`

    return (
        <div className={[classes.container, classes.bgColor].join(' ')}>
            <div className={[classes.wrapper, classes.orientation].join(' ')}>
                <div className={classes.contentContainer}>
                    <h3 className={titleClassCombined}>
                        {title}
                        <span className={classes.stop}>.</span>
                    </h3>
                    <div
                        className={classes.text}
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                    {children && <div className="pt-ms4">{children}</div>}
                </div>
                <GatsbyImage
                    image={image}
                    alt={title}
                    className={classes.image}
                    objectPosition={imagePosition}
                />
            </div>
        </div>
    )
}

export default SideBySide
