// extracted by mini-css-extract-plugin
export var container = "listTwo-module--container--ee11b w-full";
export var contentWrapper = "listTwo-module--contentWrapper--4f7b7 container mx-auto text-center mb-ms6";
export var listContainer = "listTwo-module--listContainer--9ff1a w-full bg-secondary";
export var listItemText = "listTwo-module--listItemText--f1de7 text-base lg_text-lg text-stone-500 mt-ms1";
export var listItemTitle = "listTwo-module--listItemTitle--70001 text-stone-700 mt-ms1";
export var listWrapper = "listTwo-module--listWrapper--c5c2c container mx-auto grid md_grid-cols-2 gap-ms4 md_gap-ms6 xl_gap-ms8 py-ms4 md_p-ms6 lg_p-ms8 xl_p-ms10";
export var stop = "listTwo-module--stop--7b054 text-tertiary";
export var svg = "listTwo-module--svg--a3a61 fill-none stroke-primary stroke-2";
export var text = "listTwo-module--text--9ba73 text-base md_text-lg text-stone-500 mx-auto md_w-6/12 mt-ms1";
export var title = "listTwo-module--title--a82e0 text-stone-700";