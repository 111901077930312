import React from 'react'
import { useStyle } from '../../hooks/classify'
import * as defaultClasses from './cta.module.css'

const Cta = ({ text = 'How are you?', children, parentClasses }) => {
    const classes = useStyle(defaultClasses, parentClasses)

    const titleClass = classes.title ? classes.title : ''
    const titleClassCombined = `hThree ${titleClass}`

    return (
        <div className={[classes.container, classes.bgColor].join(' ')}>
            <div className={classes.wrapper}>
                <div className={titleClassCombined}>{text}</div>
                {children && <div className="pt-ms4">{children}</div>}
            </div>
        </div>
    )
}

export default Cta
