import { twMerge } from 'tailwind-merge'

const useTailwindMerge = (defaultClasses = {}, overrideClasses = {}) => {
    // Gather all unique keys from both defaultClasses and overrideClasses
    const allKeys = new Set([
        ...Object.keys(defaultClasses),
        ...Object.keys(overrideClasses),
    ])

    const mergedClasses = [...allKeys].reduce((acc, key) => {
        // Use empty string as fallback for both default and override classes
        const defaultClass = defaultClasses[key] || ''
        const overrideClass = overrideClasses[key] || ''

        // Merge each class with its override if present, else use whatever is available
        acc[key] = twMerge(defaultClass, overrideClass)

        return acc
    }, {})

    return mergedClasses
}

export default useTailwindMerge
