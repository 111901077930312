import { graphql } from 'gatsby'
import React from 'react'
import { Seo } from '../components/seo'
import { getImage } from 'gatsby-plugin-image'
import PageTitle from '../components/pageTitle'
import { Spacer } from '../components/spacer'
import FwImgTitleText from '../components/fwImgTitleText'
import ListTwo from '../components/listTwo'
import Button from '../components/button'
import Cta from '../components/cta'
import ContentBlock from '../components/contentBlock'
import SideBySide from '../components/sideBySide'
import Extras from '../components/extras/Extras'

const TentsPage = ({ data: { markdownRemark } }) => {
    const { frontmatter } = markdownRemark

    return (
        <>
            <PageTitle
                title={frontmatter?.PageTitleTitle}
                text={frontmatter?.PageTitleText}
            />
            <Spacer />
            <FwImgTitleText
                image={getImage(
                    frontmatter.FW1_Image?.childImageSharp?.gatsbyImageData
                )}
                title={frontmatter?.FW1_Title}
                text={frontmatter?.FW1_Text}
            />
            <Spacer />
            <ListTwo listTwoData={frontmatter?.LT1_List} showTitle={false} />
            <Cta text={frontmatter?.CTA1_Text}>
                <Button
                    url="/contact"
                    text={frontmatter?.CTA1_ButtonText}
                    variation="primary"
                />
            </Cta>
            <ContentBlock
                text={frontmatter?.PRICES}
                parentClasses={{
                    container: 'w-full text-center',
                }}
            />
            <Spacer />
            <ContentBlock
                text={frontmatter?.CB_Text}
                parentClasses={{
                    container: 'w-full text-center',
                }}
            />
            <Spacer />
            <SideBySide
                image={getImage(
                    frontmatter.SBS1_Image?.childImageSharp?.gatsbyImageData
                )}
                title={frontmatter?.SBS1_Title}
                text={frontmatter?.SBS1_Text}
                parentClasses={{
                    bgColor: 'bg-light',
                }}
            />
            <SideBySide
                image={getImage(
                    frontmatter.SBS2_Image?.childImageSharp?.gatsbyImageData
                )}
                title={frontmatter?.SBS2_Title}
                text={frontmatter?.SBS2_Text}
                parentClasses={{
                    orientation: 'flex-col lg_flex-row-reverse',
                }}
            />
            <SideBySide
                image={getImage(
                    frontmatter.SBS3_Image?.childImageSharp?.gatsbyImageData
                )}
                title={frontmatter?.SBS3_Title}
                text={frontmatter?.SBS3_Text}
                parentClasses={{
                    bgColor: 'bg-light',
                }}
            />
            <Spacer />
            <ContentBlock
                text={frontmatter?.EXTRAS}
                parentClasses={{
                    container: 'w-full text-center',
                }}
            />
            <Spacer />
            <Extras extras={frontmatter?.Extras_List} />
            {/* <Spacer />
            <ContentBlock
                text={frontmatter?.HAMPERS}
                parentClasses={{
                    container: 'w-full text-center',
                }}
            /> */}
            <Spacer />
            <Cta
                text={frontmatter?.CTA2_Text}
                parentClasses={{
                    bgColor: 'bg-primary',
                    title: 'text-white',
                }}
            >
                <Button
                    url="/contact"
                    text={frontmatter?.CTA2_ButtonText}
                    variation="secondary"
                />
            </Cta>
        </>
    )
}

export default TentsPage

export const Head = ({ location, data: { markdownRemark } }) => {
    const { frontmatter } = markdownRemark
    return (
        <Seo
            title={frontmatter?.metaTitle}
            description={frontmatter?.metaDesc}
            image={getImage(
                frontmatter.metaImg?.childImageSharp?.gatsbyImageData
            )}
            pathname={location?.pathname}
        ></Seo>
    )
}

export const pageQuery = graphql`
    {
        markdownRemark(frontmatter: { id: { eq: "holiday-tents" } }) {
            frontmatter {
                metaTitle
                metaDesc
                metaImg {
                    childImageSharp {
                        gatsbyImageData(width: 1280)
                    }
                }
                PageTitleTitle
                PageTitleText
                FW1_Image {
                    childImageSharp {
                        gatsbyImageData(width: 1920)
                    }
                }
                FW1_Title
                FW1_Text
                LT1_List {
                    title
                    text
                }
                CTA1_Text
                CTA1_ButtonText
                PRICES
                CB_Text
                SBS1_Image {
                    childImageSharp {
                        gatsbyImageData(width: 1280)
                    }
                }
                SBS1_Title
                SBS1_Text
                SBS2_Image {
                    childImageSharp {
                        gatsbyImageData(width: 1280)
                    }
                }
                SBS2_Title
                SBS2_Text
                SBS3_Image {
                    childImageSharp {
                        gatsbyImageData(width: 1280)
                    }
                }
                SBS3_Title
                SBS3_Text
                EXTRAS
                Extras_List {
                    title
                    price
                    text
                    image {
                        childImageSharp {
                            gatsbyImageData(width: 1280)
                        }
                    }
                    gallery {
                        image {
                            childImageSharp {
                                gatsbyImageData(width: 1280)
                            }
                        }
                    }
                }
                HAMPERS
                CTA2_Text
                CTA2_ButtonText
            }
        }
    }
`
